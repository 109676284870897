@use "../styles/theme.scss";

:local(.exit-layout) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:local(.main) {
  padding: 0;
  margin-top: 100px;
  font-size: 24px;
  max-width: 350px;
  @media (min-width: theme.$breakpoint-sm) {
    max-width: 800px;
  }
}
