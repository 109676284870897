@use "../styles/theme.scss";

:local(.guide-layout) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
}

:local(.title) {
  font-size: theme.$font-size-2xl;
  font-weight: theme.$font-weight-bold;
  margin-top: theme.$spacing-xl;
  margin-bottom: 48px;
}

:local(.subtitle) {
  font-size: theme.$font-size-lg;
  font-weight: theme.$font-weight-bold;
  margin-right: auto;
  margin-top: theme.$spacing-md;
  margin-bottom: theme.$spacing-md;
}

:local(.maintext) {
  font-size: theme.$font-size-lg;
  margin-right: auto;
}

:local(.subtext) {
  font-size: theme.$font-size-lg;
  margin-right: auto;
  margin-left: theme.$spacing-sm;
}

:local(.main) {
  padding: 0;
  margin-top: 100px;
  font-size: 24px;
  max-width: 350px;
  @media (min-width: theme.$breakpoint-sm) {
    max-width: 800px;
  }
}
